// import logo from './logo.svg';
import Login from './components/Login';
import './App.css';

function App() {
  return (
    <div className="App">
     <Login/>
    </div>
  );
}

export default App;
